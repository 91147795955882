import React, { createContext, useState, useEffect } from 'react'
import axios from 'axios'
import { REACT_APP_API_URL } from '../utils/const'
import $api from '../http'

export const AuthContext = createContext({})

export const AuthProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [accessToken, setAccessToken] = useState(null)
  const [user, setUser] = useState({})
  const [clearMember, setClearMember] = useState(false)

  const loginHandler = async (login, password) => {
    await localStorage.removeItem('curMemberId')

    setIsLoading(true)
    axios
      .post(`${REACT_APP_API_URL}login`, { login, password })
      .then((res) => {
        setAccessToken(res.data.accessToken)
        setUser(res.data.user)
        localStorage.setItem('login', login)
        localStorage.setItem('password', password)
        // console.log("login data", res.data)
        localStorage.setItem('accessToken', res.data.accessToken)
      })
      .catch((e) => console.log(`LOGIN ERROR ${e}`))
      .finally(() => setIsLoading(false))
  }

  const registerHandler = async (activeCode, password) => {
    setIsLoading(true)
    await localStorage.removeItem('curMemberId')
    axios
      .post(`${REACT_APP_API_URL}/activate`, { activeCode, password })
      .then((res) => {
        setAccessToken(res.data.accessToken)
        setUser(res.data.user)
        localStorage.setItem('accessToken', res.data.accessToken)
      })
      .catch((e) => console.log(`ACTIVATE ERROR ${e}`))
      .finally(() => setIsLoading(false))
  }

  const logoutHandler = async () => {
    setClearMember(true)
    setIsLoading(true)

    setAccessToken(null)
    await localStorage.removeItem('accessToken')
    await localStorage.removeItem('curMemberId')
    localStorage.clear()
    setClearMember(false)
    setIsLoading(false)
  }

  const checkAuth = async () => {
    try {
      console.log(document.cookie)
      const responce = await $api.get(`/refresh`)
      console.log('REFRESH', responce.data)
      if (responce.data) {
        localStorage.setItem('accessToken', responce.data.accessToken)
        setAccessToken(responce.data.accessToken)
        setUser(responce.data.user)
        return true
      }
      return false
    } catch (e) {
      setIsLoading(true)
      localStorage.removeItem('accessToken')
      // logoutHandler()
      console.log(`check ERROR ${e}`)
      setIsLoading(false)
      return false
    }
  }

  const isLoggedIn = async () => {
    try {
      setIsLoading(true)
      const login = await localStorage.getItem('login')
      const password = await localStorage.getItem('password')
      // console.log("IS LOGGIN", password)
      if (login && password) {
        // console.log(login, " ", password)
        await loginHandler(login, password)
      } else {
        const accessToken = await localStorage.getItem('accessToken')
        if (accessToken) {
          const isLogged = await checkAuth()
          //console.log(isLogged)
          if (isLogged) {
            setAccessToken(accessToken)
          } else {
            logoutHandler()
          }
        }

        setIsLoading(false)
      }
    } catch (e) {
      setIsLoading(true)
      await localStorage.removeItem('accessToken')
      console.log(`Is Logged In Error ${e}`)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    isLoggedIn()
  }, [])

  return (
    <AuthContext.Provider
      value={{
        user,
        checkAuth,
        loginHandler,
        logoutHandler,
        isLoading,
        accessToken,
        registerHandler,
        clearMember,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
