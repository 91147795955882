import React, { useState } from "react"
import {
  IoPerson,
  IoPersonOutline,
  IoTrophy,
  IoTrophyOutline,
  IoCard,
  IoCardOutline,
  IoInformationCircle,
  IoInformationCircleOutline,
} from "react-icons/io5"
import { useNavigate } from "react-router-dom"
import { EVENTS_ROUTE, INFO_ROUTE, PAYMENTS_ROUTE, PROFILE_ROUTE } from "../utils/const"

const BotomTab = ({ text, activeTab, handleClick }) => {
  const switchIcon = (text) => {
    switch (text) {
      case "Профиль":
        if (activeTab !== text) return <IoPersonOutline size={36} />
        return <IoPerson size={36} />
      case "События":
        if (activeTab !== text) return <IoTrophyOutline size={36} />
        return <IoTrophy size={36} />

      case "Оплаты":
        if (activeTab !== text) return <IoCardOutline size={36} />
        return <IoCard size={36} />
      case "Инфо":
        if (activeTab !== text) return <IoInformationCircleOutline size={36} />
        return <IoInformationCircle size={36} />
      default:
        return <IoPersonOutline />
    }
  }

  return (
    <div onClick={handleClick} className="flex-row items-center justify-center active:text-gray-400">
      <div className="w-full flex justify-center"> {switchIcon(text)}</div>
      <p className=" text-white text-sm active:text-gray-400">{text}</p>
    </div>
  )
}

const BotomTabs = () => {
  const navigate = useNavigate()
  const [activeTab, setActiveTab] = useState("Профиль")
  return (
    <div className="w-full  p-5 mb-5 flex justify-center items-center fixed bottom-0 h-20 text-white">
      <div className="flex w-full justify-around  pt-2 pb-2 bg-red-400 rounded-xl ">
        <BotomTab
          text={"Профиль"}
          activeTab={activeTab}
          handleClick={() => {
            setActiveTab("Профиль")
            navigate(PROFILE_ROUTE)
          }}
        />
        <BotomTab
          text={"События"}
          activeTab={activeTab}
          handleClick={() => {
            setActiveTab("События")
            navigate(EVENTS_ROUTE)
          }}
        />
        <BotomTab
          text={"Оплаты"}
          activeTab={activeTab}
          handleClick={() => {
            setActiveTab("Оплаты")
            navigate(PAYMENTS_ROUTE)
          }}
        />
        <BotomTab
          text={"Инфо"}
          activeTab={activeTab}
          handleClick={() => {
            setActiveTab("Инфо")
            navigate(INFO_ROUTE)
          }}
        />
      </div>
    </div>
  )
}

export default BotomTabs
