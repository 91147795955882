import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import MainProvider from './context/MainContext'
import Firebase from './services/Firebase/Firebase.tsx'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <Firebase>
    <React.StrictMode>
      <MainProvider>
        <App />
      </MainProvider>
    </React.StrictMode>
  </Firebase>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
