export const REACT_APP_API_URL = "https://api.baza65.ru/api/"
/* export const FTP_SERVER = "ftp://5.180.136.4"
export const FTP_USER = "user2492831"
export const FTP_PASSWORD = "hpo57eqORoso" */

export const LOGIN_ROUTE = "/login"
export const REGISTER_ROUTE = "/register"
export const PROFILE_ROUTE = "/"
export const EVENTS_ROUTE = "/events"
export const ARHIV_ROUTE = "/events/arhiv"
export const PAYMENTS_ROUTE = "/payments"
export const INFO_ROUTE = "/info"
