import React, { useEffect } from 'react'
import BotomTabs from '../nav/BotomTabs'
import { Collapse, initTE } from 'tw-elements'
import Carusel from '../components/Info/Carusel'
import { stoiki, udachsti, hand, legs } from '../utils/array'

const Info = () => {
  useEffect(() => {
    initTE({ Collapse })
  }, [])

  return (
    <div>
      <div className="px-5 mt-16 pb-28 items-center justify-center ">
        <Carusel mook={stoiki} name={'Базовые стойки'} />
        <Carusel mook={udachsti} name={'Ударные поверхности'} />
        <Carusel mook={hand} name={'Удары руками'} />
        <Carusel mook={legs} name={'Удары ногами'} hei={true} />

        <div id="accordionExample">
          <div class="rounded-t-lg border border-neutral-200 bg-white dark:border-neutral-600 dark:bg-neutral-800">
            <h2 class="mb-0" id="headingOne">
              <button
                class="group relative flex w-full items-center rounded-t-[15px] border-0 bg-white px-5 py-4 text-left text-base text-neutral-800 transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none dark:bg-neutral-800 dark:text-white [&:not([data-te-collapse-collapsed])]:bg-white [&:not([data-te-collapse-collapsed])]:text-primary [&:not([data-te-collapse-collapsed])]:[box-shadow:inset_0_-1px_0_rgba(229,231,235)] dark:[&:not([data-te-collapse-collapsed])]:bg-neutral-800 dark:[&:not([data-te-collapse-collapsed])]:text-primary-400 dark:[&:not([data-te-collapse-collapsed])]:[box-shadow:inset_0_-1px_0_rgba(75,85,99)]"
                type="button"
                data-te-collapse-init
                data-te-collapse-collapsed
                data-te-target="#collapseOne"
                aria-expanded="false"
                aria-controls="collapseOne"
              >
                Общая информация
                <span class="ml-auto h-5 w-5 shrink-0 rotate-[-180deg] fill-[#336dec] transition-transform duration-200 ease-in-out group-[[data-te-collapse-collapsed]]:rotate-0 group-[[data-te-collapse-collapsed]]:fill-[#212529] motion-reduce:transition-none dark:fill-blue-300 dark:group-[[data-te-collapse-collapsed]]:fill-white">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="h-6 w-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                    />
                  </svg>
                </span>
              </button>
            </h2>
            <div
              id="collapseOne"
              class="!visible hidden"
              data-te-collapse-item
              aria-labelledby="headingOne"
              data-te-parent="#accordionExample"
            >
              <div class="px-5 py-4">
                <strong>
                  Система нормативов Киокусинкай каратэ состоит из :
                </strong>
                <br />
                - 10 ученических поясов (кю) - от белого до коричневого
                <br />
                - 10 мастерских (даны). <br />
                <br />
                <strong>Цвет пояса означает глубину освоения каратэ.</strong>
                <br />
                <br /> Белый цвет символизирует чистоту и незнание,
                <br />
                <br /> оранжевый – рождение,
                <br />
                <br /> голубой – цвет неба при восходе солнца, <br />
                <br />
                желтый – восход солнца, <br />
                <br />
                зеленый – распускающийся цветок,
                <br />
                <br />
                коричневый – зрелость,
                <br />
                <br /> черный – мудрость.
              </div>
            </div>
          </div>

          <div class="border border-t-0 border-neutral-200 bg-white dark:border-neutral-600 dark:bg-neutral-800">
            <h2 class="mb-0" id="headingTwo">
              <button
                class="group relative flex w-full items-center rounded-none border-0 bg-white px-5 py-4 text-left text-base text-neutral-800 transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none dark:bg-neutral-800 dark:text-white [&:not([data-te-collapse-collapsed])]:bg-white [&:not([data-te-collapse-collapsed])]:text-primary [&:not([data-te-collapse-collapsed])]:[box-shadow:inset_0_-1px_0_rgba(229,231,235)] dark:[&:not([data-te-collapse-collapsed])]:bg-neutral-800 dark:[&:not([data-te-collapse-collapsed])]:text-primary-400 dark:[&:not([data-te-collapse-collapsed])]:[box-shadow:inset_0_-1px_0_rgba(75,85,99)]"
                type="button"
                data-te-collapse-init
                data-te-collapse-collapsed
                data-te-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                Нормотивы на пояса
                <span class="-mr-1 ml-auto h-5 w-5 shrink-0 rotate-[-180deg] fill-[#336dec] transition-transform duration-200 ease-in-out group-[[data-te-collapse-collapsed]]:mr-0 group-[[data-te-collapse-collapsed]]:rotate-0 group-[[data-te-collapse-collapsed]]:fill-[#212529] motion-reduce:transition-none dark:fill-blue-300 dark:group-[[data-te-collapse-collapsed]]:fill-white">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="h-6 w-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                    />
                  </svg>
                </span>
              </button>
            </h2>
            <div
              id="collapseTwo"
              class="!visible hidden"
              data-te-collapse-item
              aria-labelledby="headingTwo"
              data-te-parent="#accordionExample"
            >
              <div class="px-5 py-4">
                <ul>
                  <li>Ораньжевый пояс 10 кю</li>
                  <li>Ораньжевый пояс с полоской 9 кю</li>
                  <li>Синий пояс 8 кю</li>
                  <li>Синий пояс с полоской 7 кю</li>
                  <li>Жёлтый пояс 6 кю</li>
                  <li>Жёлтый пояс с полоской 5 кю</li>
                  <li>Зелёный пояс 4 кю</li>
                  <li>Зелёный пояс с полоской 3 кю</li>
                  <li>Коричневый пояс 2 кю</li>
                  <li>Коричневый пояс с полоской 1 кю</li>
                </ul>
              </div>
            </div>
          </div>

          <div class="rounded-b-lg border border-t-0 border-neutral-200 bg-white dark:border-neutral-600 dark:bg-neutral-800">
            <h2 class="accordion-header mb-0" id="headingThree">
              <button
                class="group relative flex w-full items-center border-0 bg-white px-5 py-4 text-left text-base text-neutral-800 transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none dark:bg-neutral-800 dark:text-white [&:not([data-te-collapse-collapsed])]:bg-white [&:not([data-te-collapse-collapsed])]:text-primary [&:not([data-te-collapse-collapsed])]:[box-shadow:inset_0_-1px_0_rgba(229,231,235)] dark:[&:not([data-te-collapse-collapsed])]:bg-neutral-800 dark:[&:not([data-te-collapse-collapsed])]:text-primary-400 dark:[&:not([data-te-collapse-collapsed])]:[box-shadow:inset_0_-1px_0_rgba(75,85,99)] [&[data-te-collapse-collapsed]]:rounded-b-[15px] [&[data-te-collapse-collapsed]]:transition-none"
                type="button"
                data-te-collapse-init
                data-te-collapse-collapsed
                data-te-target="#collapseThree"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                Термины
                <span class="-mr-1 ml-auto h-5 w-5 shrink-0 rotate-[-180deg] fill-[#336dec] transition-transform duration-200 ease-in-out group-[[data-te-collapse-collapsed]]:mr-0 group-[[data-te-collapse-collapsed]]:rotate-0 group-[[data-te-collapse-collapsed]]:fill-[#212529] motion-reduce:transition-none dark:fill-blue-300 dark:group-[[data-te-collapse-collapsed]]:fill-white">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="h-6 w-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                    />
                  </svg>
                </span>
              </button>
            </h2>
            <div
              id="collapseThree"
              class="!visible hidden"
              data-te-collapse-item
              aria-labelledby="headingThree"
              data-te-parent="#accordionExample"
            >
              <div class="px-5 py-4">
                <strong>Сосай</strong> – основатель стиля – Масутацу Ояма.{' '}
                <br />
                <strong>Канчо (Кантё)</strong> – директор организации <br />
                <strong>Шихан (Сихан)</strong> – мастер с 5-го Дана. <br />
                <strong>Сэнсэй </strong>– учитель, наставник (3-ий, 4-ый дан).{' '}
                <br />
                <strong>Сэмпай</strong> – старший ученик. <br />
                <strong>Кохай </strong>– младший ученик. <br />
                <strong>Доджо</strong> (Додзё) – зал (место следования Пути).{' '}
                <br />
                <strong>Дан</strong> – мастерская степень. <br />
                <strong>Кю</strong>– ученическая степень. <br />
                <strong>Доги</strong> – одежда для тренировок. <br />
                <strong>Кихон</strong> – тренировка техники без партнёра. <br />
                <strong>Рэнраку </strong>– комбинации. <br />
                <strong>Ката</strong> – форма, комплекс <br />
                <strong>Кумитэ</strong> – спарринг. <br />
                <strong>Самбон кумитэ</strong> – условный учебный спарринг на 3
                шага. <br />
                <strong>Якусоку кумитэ</strong> – условный учебный спарринг на 1
                шаг. <br />
                <strong>Джию кумитэ (Дзию кумитэ)</strong> – свободный спарринг.{' '}
                <br />
                <strong>Тамэшивари (Тамэсивари)</strong> – разбивание твёрдых
                предметов. <br />
                <strong>Бункай</strong> – разъяснение. <br />
                <strong>Ибуки</strong> – силовое дыхание. <br />
                <strong>Ногарэ </strong>– мягкое дыхание. <br />
                <strong>Осу (Ос) </strong>- традиционная форма приветствия,
                выражения понимания, согласия и уверенности.
                <br />
                <br />
                <br />
                <strong>Уровни атаки и защиты</strong>
                <br />
                <strong>Джодан (Дзёзан)</strong> – верхний (голова, шея). <br />
                <strong> Чудан (Тюдан)</strong> – средний (корпус). <br />
                <strong>Гэдан</strong> – нижний (ниже пояса).
                <br />
                <br />
                <br />
                <strong>Стороны</strong>
                <br />
                <strong>Хидари </strong>– левая. <br />
                <strong>Миги </strong>– правая.
                <br />
                <br />
                <br />
                <strong>Направления</strong>
                <br />
                <strong> Аго</strong> – вверх. <br />
                <strong> Маэ </strong>– вперёд. <br />
                <strong> Ёко</strong> – в сторону. <br />
                <strong>Уширо (Усиро)</strong> – назад. <br />
                <strong> Маваши (Маваси)</strong> – круговое. <br />
                <strong> Ороши (Ороси)</strong> – вниз.
                <br />
                <br />
                <br />
                <strong>Счёт</strong>
                <br />
                <strong> Ичи (ити) </strong>– 1 <br />
                <strong> Ни </strong>– 2 <br />
                <strong>Сан </strong>– 3 <br />
                <strong> Ши (ён) (си)</strong> – 4 <br />
                <strong> Го </strong>– 5 <br />
                <strong> Року </strong>– 6 <br />
                <strong> Шичи (Сити) </strong>- 7 <br />
                <strong> Хачи (Хати)</strong> – 8 <br />
                <strong> Ку </strong>– 9 <br />
                <strong> Джу (Дзю) </strong>– 10
              </div>
            </div>
          </div>
        </div>
      </div>
      <BotomTabs />
    </div>
  )
}

export default Info
