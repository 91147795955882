import axios from "axios"
export const API_URL = "https://api.baza65.ru/api"

const $api = axios.create({
  withCredentials: true,
  baseURL: API_URL,
  credentials: "include",
})

$api.interceptors.request.use(
  async (config) => {
    const token = await localStorage.getItem("accessToken")
    config.headers.Authorization = `Bearer ${token}`
    config.headers["Content-Type"] = "application/json"

    return config
  },
  (error) => {
    Promise.reject(error)
  }
)

export default $api
