import React, { createContext, useContext, useEffect, useState } from "react"
import { MemberContext } from "./MemberContext"
import $api from "../http"

export const EventContext = createContext()

export const EventProvider = ({ children }) => {
  const { memberFetching, curMember, allMembers } = useContext(MemberContext)

  const [allEventList, setAllEventList] = useState([])
  const [allCalendar, setAllCalendar] = useState([])

  useEffect(() => {
    if (curMember?._id) {
      getIvent(curMember._id)
      getCalendarPlan()
      //console.log(curMember)
    }
  }, [curMember])

  const getIvent = async (id) => {
    try {
      const resp = await $api.get(`/ivent/getbymemberid/${id}`)
      // console.log(resp.data)
      setAllEventList(resp.data)
    } catch (e) {
      console.log(`getIvent EventProvider ERROR ${e}`)
    }
  }

  const getCalendarPlan = async () => {
    try {
      const resp = await $api.get("/calendarplan/getall")
      setAllCalendar(resp.data)
    } catch (e) {
      console.log(`getCalendarPlan EventCTX ${e}`)
    }
  }

  return <EventContext.Provider value={{ allEventList, allCalendar }}>{children}</EventContext.Provider>
}
