import React, { useContext, useState } from "react"
import { NavLink, useNavigate } from "react-router-dom"
import { AuthContext } from "../context/AuthContext"

const Register = () => {
  const [activCode, setActiveCode] = useState("")
  const [password, setPassword] = useState("")
  const { registerHandler } = useContext(AuthContext)
  const navigate = useNavigate()

  const clickRegister = async () => {
    const isLogin = await registerHandler(activCode, password)
    if (isLogin) {
      navigate("/profile")
    }
  }

  return (
    <div className="flex w-screen h-screen bg-white">
      <div className="w-full flex-row justify-center content-center mt-5">
        <div className="flex flex-col w-full items-center justify-center">
          <img className="h-32" src="logo.png" alt="logo" />
          <img className=" h-40" src="tlogo.png" alt="tlogo" />
        </div>
        <div className="w-full flex justify-center items-center">
          <div className=" flex flex-col w-64  ">
            <input
              style={{ lineHeight: 1 }}
              className="outline-none w-full border-2 p-2 rounded-md"
              type="tel"
              placeholder="Код активации..."
              value={activCode}
              onChange={(e) => setActiveCode(e.currentTarget.value)}
            />

            <input
              className="outline-none w-full border-2 p-2 rounded-md mt-2"
              type="password"
              placeholder="Введите ваш пароль..."
              value={password}
              onChange={(e) => setPassword(e.currentTarget.value)}
            />
            <div className="flex justify-between">
              <button onClick={() => clickRegister()} className="border-2 border-amber-400 mt-3 px-2 py-1 rounded-md">
                Активировать
              </button>
              <NavLink className=" mt-3 px-2 py-1 rounded-md" to={"/login"}>
                Вход
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Register
