import React, { useContext, useEffect, useState } from 'react'
import { BrowserRouter } from 'react-router-dom'
import AppRouter from './routes/AppRouter'
import { AuthContext } from './context/AuthContext'
import BotomTabs from './nav/BotomTabs'
import Header from './components/Header'
import { GetToken } from './utils/firebase'

const App = () => {
  const { accessToken, isLoading } = useContext(AuthContext)
  const [isTokenFound, setTokenFound] = useState(false)
  useEffect(() => {
    //  notifyMe()
    //  GetToken(setTokenFound)
  }, [])

  function notifyMe() {
    if ('serviceWorker' in navigator && 'PushManager' in window) {
      // Register a service worker
      navigator.serviceWorker
        .register('firebase-messaging-sw.js')
        .then(function (registration) {
          console.log(
            'Service Worker registered with scope:',
            registration.scope
          )

          // Request permission for push notifications
          return registration.pushManager
            .getSubscription()
            .then(function (subscription) {
              if (!subscription) {
                return registration.pushManager.subscribe({
                  userVisibleOnly: true,
                  applicationServerKey:
                    'BDx3sF31pxwJGAnf_ShVONmaSnbmTjdtZnaxFdHeGo0zZfkyB32S6jjVpTMa0SUFZHFljaRVHg0IM6A1YIlxMl4',
                })
              }
            })
        })
        .then(function (subscription) {
          // Subscription successful
          console.log('Subscribed with endpoint:', subscription.endpoint)
        })
        .catch(function (error) {
          console.error('Error registering service worker:', error)
        })
    } else {
      console.warn('Service workers are not supported in this browser')
    }
  }

  if (!isLoading) {
    return (
      <BrowserRouter>
        {accessToken && <Header />}
        <AppRouter />
        {accessToken && <BotomTabs />}
      </BrowserRouter>
    )
  }
}

export default App
