import React, { createContext, useContext, useEffect, useState } from 'react'
import { AuthContext } from './AuthContext'
import $api from '../http'
import { MemberContext } from './MemberContext'

export const PaymentContext = createContext()
export const PaymentProvider = ({ children }) => {
  const { isLoading, user, accessToken, clearMember } = useContext(AuthContext)
  const { allMembers } = useContext(MemberContext)
  const [allDebitors, setAllDebitors] = useState([])
  const [allPays, setAllPays] = useState([])
  const [allTarif, setAllTarif] = useState([])
  const [allIvent, setAllIvent] = useState([])
  const [allEvent, setAllEvent] = useState([])
  const [allMomentPay, setAllMomentPay] = useState([]) // Добавить разовые платежи

  useEffect(() => {
    if (!isLoading && accessToken) {
      getDebitor(user.id)
      getPays()
      getEvent()
    }
  }, [isLoading])

  useEffect(() => {}, [clearMember])

  useEffect(() => {
    splitDebitors()
  }, [allDebitors])

  const splitDebitors = () => {
    //console.log(allDebitors)
    setAllTarif(allDebitors.filter((d) => d.paysID?.length > 0))
    setAllIvent(allDebitors.filter((d) => d.iventID?.length > 0))
  }

  const getTarifCost = (paysID) => {
    if (paysID) {
      const resp = allPays.filter((p) => p._id === paysID)

      if (resp[0]?.cost) return resp[0].cost
    }
    return 0
  }

  const getIventMember = (id) => {
    if (id) {
      //console.log(allMembers)
      const user = allMembers.filter((m) => m._id === id)

      return user[0]
    }
  }

  const getIventCost = (iventID) => {
    if (iventID) {
      const resp = allEvent.filter((e) => e._id === iventID)

      if (resp[0]?.cost) return resp[0].cost
    }
    return 0
  }

  const getIventName = (iventID) => {
    if (iventID) {
      const resp = allEvent.filter((e) => e._id === iventID)
      console.log(resp[0])
      if (resp[0]?.cost) return resp[0].name
    }
    return 0
  }

  const getPays = async () => {
    try {
      const resp = await $api.get('/pay/getall')
      setAllPays(resp.data)
    } catch (e) {
      console.log(`getPays ERROR ${e}`)
    }
  }

  const ykassaPay = async (debid) => {
    try {
      const resp = await $api.post('/ykassa/payment', { debid, client: 'pwa' })
      console.log('yakassaPAY', resp.data)
      window.location.replace(resp.data)
    } catch (e) {
      console.log(`ykassaPAY ERROR ${e}`)
    }
  }

  const getEvent = async () => {
    try {
      const resp = await $api.get('/ivent/getall')
      setAllEvent(resp.data)
    } catch (e) {
      console.log(`getEvent ERROR ${e}`)
    }
  }

  const getDebitor = async (userID) => {
    try {
      const resp = await $api.get(`/debitor/getbyuserid/${userID}`)
      // console.log(resp.data)
      if (resp.data.length > 0) {
        setAllDebitors(resp.data)
      }
    } catch (e) {
      console.log(`getDebitor ERROR ${e}`)
    }
  }

  return (
    <PaymentContext.Provider
      value={{
        getIventMember,
        allMomentPay,
        allDebitors,
        allTarif,
        allIvent,
        getTarifCost,
        getIventCost,
        getIventName,
        ykassaPay,
      }}
    >
      {children}
    </PaymentContext.Provider>
  )
}
