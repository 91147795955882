import React from 'react'
import { FirebaseProvider } from '@useweb/use-firebase'
import { initializeApp } from 'firebase/app'
import { getMessaging } from 'firebase/messaging'

const firebaseConfig = {
  apiKey: 'AIzaSyBugP_bLxXH1GIRMHOnj9XOq8Xl01bLBhE',
  authDomain: 'karate-app-7892b.firebaseapp.com',
  projectId: 'karate-app-7892b',
  storageBucket: 'karate-app-7892b.appspot.com',
  messagingSenderId: '730658938755',
  appId: '1:730658938755:web:e3192fb906a41cad44a0b6',
  measurementId: 'G-4GZDDK6VSM',
}

const firebaseApp = initializeApp(firebaseConfig)
const messaging = getMessaging(firebaseApp)

const envIsDev = process.env.NODE_ENV === 'development'

//const vapidKey = 'gO9SJZ-5V3OijZucvl5FZkKYwXCS3X6UGg7brYQ4JtQ'
const vapidKey = 'BJc3Xwotn9-97IlO_Y7Pnz0k6ynokxkVzy7Nt5uc22OxTDXsUNfAu_tJdbrm'
export default function Firebase({ children }) {
  return (
    <FirebaseProvider
      firebaseConfig={firebaseConfig}
      firebaseApp={firebaseApp}
      envIsDev={envIsDev}
      messaging={messaging}
      messagingOptions={{
        vapidKey,
      }}
    >
      {children}
    </FirebaseProvider>
  )
}
