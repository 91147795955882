import React, { useEffect } from 'react'
import { Lightbox, initTE } from 'tw-elements'

const Carusel = ({ mook, name, hei }) => {
  useEffect(() => {
    initTE({ Lightbox })
  }, [])
  let cardHeight =
    ' overflow-hidden  w-72 h-[400px] block rounded-lg bg-white p-3 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700'
  if (hei) {
    cardHeight =
      ' overflow-hidden  w-72 h-[570px] block rounded-lg bg-white p-3 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700'
  }
  console.log(cardHeight)
  return (
    <div>
      <h1 className=" text-center text-2xl font-bold">{name}</h1>
      <div>
        <ul className=" flex flex-row gap-4 overflow-y-auto">
          {mook.map((item, index) => {
            return (
              <li key={index} className=" my-2 mx-2  ">
                <div data-te-lightbox-init class={cardHeight}>
                  <img
                    className="cursor-zoom-in data-[te-lightbox-disabled]:cursor-auto"
                    data-te-img={item.link}
                    src={item.link}
                    alt=""
                  />
                  {item.link2 !== '' && (
                    <div>
                      <img
                        className="cursor-zoom-in data-[te-lightbox-disabled]:cursor-auto"
                        data-te-img={item.link2}
                        src={item.link2}
                        alt=""
                      />
                    </div>
                  )}
                  <div className=" flex items-center justify-center">
                    <p class="mb-4 text-base text-neutral-600 dark:text-neutral-200">
                      {item.name}
                    </p>
                  </div>
                  <p class="mb-4 text-base text-neutral-600 dark:text-neutral-200">
                    {item.description}
                  </p>
                </div>
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}

export default Carusel
