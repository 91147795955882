import React, { useContext } from "react"
import { MemberContext } from "../context/MemberContext"

const Profile = () => {
  const { memberFetching, curMemberHandler, curTrener, raspisanie, curGroup, curMember, allMembers, clearMember } =
    useContext(MemberContext)
  console.log(memberFetching)
  console.log(curMember)
  console.log(allMembers)

  const getAge = (bd) => Math.floor((new Date() - new Date(bd).getTime()) / 3.15576e10)
  const getBelt = (belt) => {
    switch (belt) {
      case 12:
        return "Белый"
      case 11:
        return "Белый с полоской"
      case 10:
        return "Оранжевый"
      case 9:
        return "Оранжевый с полоской"
      case 8:
        return "Синий"
      case 7:
        return "Синий с полоской"
      case 6:
        return "Жёлтый"
      case 5:
        return "Жёлтый с полоской"
      case 4:
        return "Зелёный"
      case 3:
        return "Зелёный с полоской"
      case 2:
        return "Коричневый"
      case 1:
        return "Коричневый с полоской"

      default:
        return "Белый"
    }
  }

  let kunum = []

  for (let i = 1; i < 12; i++) {
    if (curMember["ku" + i + "num"] !== "") {
      kunum.push(
        <div className="flex justify-between w-full">
          <span>Сертификат {i} кю</span>
          <span>{curMember["ku" + i + "num"]}</span>
        </div>
      )
      break
    }
  }
  const beltLink = "belt/" + curMember.belt + ".png"
  return (
    <div className="w-full top-1 absolute pb-28 ">
      {allMembers && allMembers.length > 0 ? (
        <div className="flex flex-col justify-center items-center mt-10">
          {allMembers && allMembers.length > 1 && (
            <div className="flex absolute top-1 left-1">
              {allMembers.map((m) => {
                return (
                  <div className="flex items-start space-y-1 ml-5 " onClick={() => curMemberHandler(m._id)}>
                    <div className="border-2 border-red-300 rounded-3xl px-5 py-2">
                      <span>{m.lname}</span>
                    </div>
                  </div>
                )
              })}
            </div>
          )}

          <div className="mt-5 font-bold text-lg">
            {curMember.fname} {curMember.lname}
          </div>
          <div>{curMember.belt < 11 && <img src={beltLink} alt="belt" />}</div>
          <div className="w-full px-10 mt-5 text-sm">
            <div className="flex justify-between w-full">
              <span>Возраст</span>
              <span>{getAge(curMember.bdate)}</span>
            </div>
            <div className="flex justify-between w-full">
              <span>Рост</span>
              <span>{curMember.heigth}</span>
            </div>
            <div className="flex justify-between w-full">
              <span>Вес</span>
              <span>{curMember.weight}</span>
            </div>
            <div className="flex justify-between w-full">
              <span>Группа</span>
              <span>{curGroup}</span>
            </div>
            <div className="flex justify-between w-full">
              <span>Тренер</span>
              <span>{curTrener}</span>
            </div>
            <div className="flex justify-between w-full">
              <span>Пояс</span>
              <span>{getBelt(curMember.belt)}</span>
            </div>
            <div className="flex justify-between w-full">
              <span>№ будопаспорта</span>
              <span>{curMember.budonum}</span>
            </div>
            <div className="flex justify-between w-full">
              <span>№ карточки iko</span>
              <span>{curMember.ikonum}</span>
            </div>
            {kunum}
          </div>
          <div className="flex flex-col items-center justify-center w-full px-5 mt-5">
            <span className="font-bold text-lg">Расписание занятий</span>
            {raspisanie.map((r) => {
              return (
                <div className="border-2 w-full flex justify-between px-5 m-1 bg-gray-100" key={r._id}>
                  <span className="border-b-1 border-neutral-100 border-opacity-100 py-2 dark:border-opacity-50">
                    {r.day}
                  </span>
                  <span className="border-b-1 border-neutral-100 border-opacity-100 py-2 dark:border-opacity-50">
                    {r.time_start} - {r.time_end}
                  </span>
                </div>
              )
            })}
          </div>
        </div>
      ) : (
        <div className="mt-20 p-3">
          <span>Похоже у вас нет добавленых учеников. Обратитесь к тренеру!</span>
        </div>
      )}
    </div>
  )
}

export default Profile
