import { initializeApp } from 'firebase/app'
import { getMessaging, getToken, onMessage } from 'firebase/messaging'
import $api from '../http'

const firebaseConfig = {
  apiKey: 'AIzaSyBugP_bLxXH1GIRMHOnj9XOq8Xl01bLBhE',
  authDomain: 'karate-app-7892b.firebaseapp.com',
  projectId: 'karate-app-7892b',
  storageBucket: 'karate-app-7892b.appspot.com',
  messagingSenderId: '730658938755',
  appId: '1:730658938755:web:e3192fb906a41cad44a0b6',
  measurementId: 'G-4GZDDK6VSM',
}

const firebaseApp = initializeApp(firebaseConfig)
const messaging = getMessaging(firebaseApp)

const TestPushToken = async (token) => {
  console.log('QWEQWEQWE', token)
  const resp = await $api.get(`/test/tokenPush/${token}`)
}

export const GetToken = (setTokenFound) => {
  return getToken(messaging, {
    vapidKey:
      'BDx3sF31pxwJGAnf_ShVONmaSnbmTjdtZnaxFdHeGo0zZfkyB32S6jjVpTMa0SUFZHFljaRVHg0IM6A1YIlxMl4',
  })
    .then((currentToken) => {
      if (currentToken) {
        console.log('current token for client: ', currentToken)
        TestPushToken(currentToken)
        setTokenFound(true)

        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
      } else {
        console.log(
          'No registration token available. Request permission to generate one.'
        )
        setTokenFound(false)
        // shows on the UI that permission is required
      }
    })
    .catch((err) => {
      console.log('An error occurred while retrieving token. ', err)
      // catch error while creating client token
    })
}
