import React, { useContext } from "react"
import { AuthContext } from "../context/AuthContext"
import { IoExitOutline } from "react-icons/io5"

const Header = () => {
  const { logoutHandler } = useContext(AuthContext)
  return (
    <div className="pb-10 z-10 absolute top-0">
      <div className="flex justify-end pr-3 pt-3 text-red-500 fixed right-3" onClick={() => logoutHandler()}>
        <IoExitOutline size={32} />
      </div>
    </div>
  )
}

export default Header
