import React, { useContext, useEffect, useState } from "react"
import { NavLink, useNavigate } from "react-router-dom"
import { AuthContext } from "../context/AuthContext"

const Login = () => {
  const navigate = useNavigate()

  const [login, setLogin] = useState("")
  const [password, setPassword] = useState("")

  const { loginHandler } = useContext(AuthContext)

  const clickLogin = async () => {
    const isLogin = await loginHandler(login, password)
    if (isLogin) {
      navigate("/profile")
    }
  }
  useEffect(() => {
    // window.location.replace("krtclb://open")
  }, [])

  return (
    <div className="flex w-screen h-screen bg-white">
      <div className="w-full flex-row justify-center content-center mt-5">
        <div className="flex flex-col w-full items-center justify-center">
          <img className="h-32" src="logo.png" alt="logo" />
          <img className=" h-40" src="tlogo.png" alt="tlogo" />
        </div>
        <div className="w-full flex justify-center items-center">
          <div className=" flex flex-col w-64  ">
            <div className="flex flex-row w-full h-10 border-2 p-0 rounded-md items-center justify-center ">
              <div className="flex w-[10%] ">+7 </div>

              <input
                style={{ lineHeight: 1 }}
                className="outline-none w-[80%] "
                type="tel"
                placeholder="900-123-45-67"
                value={login}
                onChange={(e) => setLogin(e.currentTarget.value)}
              />
            </div>

            <input
              className="outline-none w-full border-2 p-2 rounded-md mt-2"
              type="password"
              placeholder="Введите ваш пароль..."
              value={password}
              onChange={(e) => setPassword(e.currentTarget.value)}
            />
            <div className="flex justify-between">
              <button className="border-2 border-red-400 mt-3 px-2 py-1 rounded-md" onClick={() => clickLogin()}>
                Войти
              </button>
              <NavLink className=" mt-3 px-2 py-1 rounded-md" to={"/register"}>
                Регистрация
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login
