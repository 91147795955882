import React, { useContext } from "react"
import { Routes, Route, Navigate } from "react-router-dom"
import { authRouter, publicRouter } from "./mainRoute"
import { LOGIN_ROUTE, PROFILE_ROUTE } from "../utils/const"
import { AuthContext } from "../context/AuthContext"

function AppRouter() {
  const { accessToken } = useContext(AuthContext)
  //console.log(accessToken)

  return (
    <Routes>
      {accessToken
        ? authRouter.map(({ path, Component }) => {
            return <Route key={path} path={path} element={<Component />} />
          })
        : publicRouter.map(({ path, Component }) => {
            return <Route key={path} path={path} element={<Component />} />
          })}
      {accessToken === null ? (
        <Route path="*" element={<Navigate to={LOGIN_ROUTE} replace />} />
      ) : (
        <Route path="*" element={<Navigate to={PROFILE_ROUTE} replace />} />
      )}
    </Routes>
  )
}

export default AppRouter
