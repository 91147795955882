import React, { createContext, useContext, useEffect, useState } from 'react'
import $api from '../http'
import { AuthContext } from './AuthContext'

export const MemberContext = createContext()
export const MemberProvider = ({ children }) => {
  const { isLoading, user, accessToken, clearMember } = useContext(AuthContext)
  const [curMember, setCurMember] = useState({})
  const [curGroup, setCurGroup] = useState('')
  const [raspisanie, setRaspisanie] = useState([])
  const [allMembers, setAllMembers] = useState({})
  const [curTrener, setCurTrener] = useState('')
  const [memberFetching, setMemberFetchng] = useState(true)

  useEffect(() => {
    if (!isLoading && accessToken) {
      getMember(user.id)
    }
  }, [isLoading])

  useEffect(() => {
    if (allMembers.length > 0) {
      curMemberHandler()
      setMemberFetchng(false)
    }
  }, [allMembers])

  useEffect(() => {
    setMemberFetchng(true)
    setCurMember({})
    setCurGroup('')
    setCurTrener('')
    setRaspisanie([])
    setAllMembers({})
  }, [clearMember])

  const curMemberHandler = async (id = 0) => {
    try {
      setMemberFetchng(true)
      const curMemberId = await localStorage.getItem('curMemberId')
      let cm
      if (curMemberId && id === 0) {
        cm = allMembers.filter((m) => m._id === curMemberId)
      } else if (id !== 0) {
        cm = allMembers.filter((m) => m._id === id)
      } else {
        cm = allMembers
        await localStorage.setItem('curMemberId', allMembers[0]._id)
      }

      setCurMember(cm[0])
      const gr = await getGroup(cm[0].groupID)
      if (gr) {
        //console.log(gr)
        getTrener(gr.personalID)
        setCurGroup(gr.name)
        setRaspisanie(gr.raspisanie)
        setMemberFetchng(false)
      }
      // console.log(cm[0])
    } catch (e) {
      console.log(`CurMember In MemberCOntext ERROR ${e}`)
    }
  }

  const getTrener = async (personalID) => {
    try {
      const resp = await $api.get(`/personal/getbyid/${personalID}`)
      if (resp) {
        setCurTrener(resp.data.name)
      }
    } catch (e) {
      console.log(`getTrener ERROR ${e}`)
    }
  }

  const getMember = async (userID) => {
    try {
      const resp = await $api.get(`/member/getfromuid/${userID}`, {
        params: { userID },
      })

      setAllMembers(resp.data)
    } catch (e) {
      console.log(`MemberContext getMember ${e}`)
    }
  }

  const getGroup = async (groupID) => {
    const resp = await $api.get(`group/getbyid/${groupID}`)
    //console.log(resp.data)
    return resp.data
  }

  return (
    <MemberContext.Provider
      value={{
        memberFetching,
        curMemberHandler,
        curTrener,
        raspisanie,
        curGroup,
        curMember,
        allMembers,
        clearMember,
      }}
    >
      {children}
    </MemberContext.Provider>
  )
}
