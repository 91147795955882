export const legs = [
  {
    name: 'Маэ Гери',
    link: 'legs/MaeGeri.gif',
    link2: 'legs/MaeGeri1.jpg',
    description:
      'Прямой удар стопой вперёд. Сначала поднимаете согнутое колено как можно выше, потом резко распрямляете ногу ударом вперёд, после удара нога резко отдёргивается назад в обратной последовательности. (Удар наносится чесоку)',
  },
  {
    name: 'Ёко Гери',
    link: 'legs/YokoGeri.gif',
    link2: 'legs/YokoGeri1.jpg',
    description:
      'Прямой удар ребром ноги в сторону. Сначала подъём колена вверх, затем разворот бедра, направление в сторону противника сокуто, затем резкое разгибание колена, доворот бедра и разворот пятки в сторону противника. После удара нога взвращается в обратной последовательности.',
  },
  {
    name: 'Уширо Гери',
    link: 'legs/UshiroGeri.gif',
    link2: 'legs/UshiroGeri1.jpg',
    description:
      'Прямой удар ногой назад. Сначала сгибаем колено и резко разгибаем ногу в сторону противника (удар какато). После удара резко возвращаем ногу в исходное положение.',
  },
  {
    name: 'Хидза Гери',
    link: 'legs/HizaGeri.gif',
    link2: '',
    description:
      'Удар коленом. Важно чтобы колено было максимально согнуто в момент удара.',
  },
  {
    name: 'Кансетсу Гери',
    link: 'legs/KansetsuGeri-1.jpg',
    link2: '',
    description:
      'Удар ребром стопы в коленный сустав. Удар наносится как спереди так и с зади.Большой палец ударной ноги поднят вверх, остальные согнуты вниз.',
  },
  {
    name: 'Маваши Гери',
    link: 'legs/MawashiGeri.gif',
    link2: '',
    description:
      'Удар сбоку по дуге. Сначала поднимаем колено, затем разворачиваем голень в горизонтальной плоскости и резко распрямляем ногу. После удара резко возвращаемся в исходную стойку.',
  },
  {
    name: 'Гедан Маваши Гери',
    link: 'legs/MawashiGeriGedan.jpg',
    link2: '',
    description:
      'Лоукик. Удар приходится на внутреннюю или внешнюю сторону ног противника. Сначало сгибаем колено, потом резко рагибаем ногу. После удара резко возвращаемся в исходную стойку.',
  },
  {
    name: 'Уширо Маваши Гери',
    link: 'legs/UshiroMawashiGeri.gif',
    link2: '',
    description:
      'Обратный удар ногой с разворота (вертушка). Нога идёт по круговой траектории и сила зависит от поворота бедра.',
  },
  {
    name: 'Хидза Маваши Гери',
    link: 'legs/MawashiHizaGeri.png',
    link2: '',
    description:
      'Круговой удар коленом. Удар выполняется круговым движением колена.',
  },
  {
    name: 'Аши Барай',
    link: 'legs/AshiBarai.png',
    link2: '',
    description: 'Подсечка.',
  },
  {
    name: 'Тоби Ёко Гери',
    link: 'legs/TobiYokoGeri.png',
    link2: '',
    description: 'Прямой удар в прыжке в сторону.',
  },
  {
    name: 'Тоби Маэ Гери',
    link: 'legs/TobiMaeGeri.png',
    link2: '',
    description: 'Прямой удар в пыжке вперёд.',
  },
  {
    name: 'Тоби Уширо Гери',
    link: 'legs/TobiUshiroGeri.png',
    link2: '',
    description: 'Удар ногой назад в прыжке.',
  },
  {
    name: 'Тоби Хидза Гери',
    link: 'legs/TobiHizaGeri.png',
    link2: '',
    description: 'Удар в прыжке коленом.',
  },
]

export const hand = [
  {
    name: 'Сейкен Ои Цуке',
    link: 'hand/Seiken-Oi-Tsuki-1.jpg',
    description: 'Удар сейкен под переднюю ногу.',
  },
  {
    name: 'Сейкен Гяку Цуке',
    link: 'hand/Seiken-Gyaku-Tsuki-1.jpg',
    description: 'Удар сейкен под заднюю ногу.',
  },
  {
    name: 'Татэ Цуке',
    link: 'hand/Tate-Tsuki-1.jpg',
    description: 'Удар сейкен. Кулак расположен вертикально.',
  },
  {
    name: 'Джун Цуке',
    link: 'hand/Jun-tsuki-1.jpg',
    description: 'Удар сейкен в сторону.',
  },
  {
    name: 'Сейкен Моротэ Цуке Чудан',
    link: 'hand/Seiken-morote-tsuki-chudan-1.jpg',
    description: 'Удар сейкен двумя руками в корпус.',
  },
  {
    name: 'Сейкен Моротэ Цуке Гедан',
    link: 'hand/Seiken-morote-tsuki-gedan-1.jpg',
    description: 'Удар сейкен двумя руками ниже пояса.',
  },
  {
    name: 'Сейкен Моротэ Цуке Джодан',
    link: 'hand/Seiken-morote-tsuki-jodan-1.jpg',
    description: 'Удар сейкен двумя руками в голову.',
  },
  {
    name: 'Тэтцуй Коми Ками Учи',
    link: 'hand/Tettsui-komekami.jpg',
    description: 'Удар тэтсуй сбоку по голове в висок.',
  },
  {
    name: 'Тэтсуй Ороши Гаммэн Учи',
    link: 'hand/Tettsui-oroshi-ganmen-uchi.jpg',
    description: 'Удар тэтсуй сверху по голове.',
  },
  {
    name: 'Тэтсуй Хидзо Учи',
    link: 'hand/Tettsui-hizo-uchi.jpg',
    description: 'Удар тэтсуй по рёбрам. Размах от уха.',
  },
  {
    name: 'Уракен Ороши Ганмэн Учи',
    link: 'hand/Uraken-oroshi-ganmen-uchi.jpg',
    description: 'Удар уракэн в лицо.',
  },
  {
    name: 'Уракэн Саю Учи',
    link: 'hand/Uraken-sayu-ganmen-uchi.jpg',
    description: 'Удар уракэн в сторону в лицо.',
  },
  {
    name: 'Уракэн Хизо Учи',
    link: 'hand/Uraken-hizo-uchi.jpg',
    description: 'Удар уракэн в сторону по рёбрам.',
  },
  {
    name: 'Уракэн Маваши Учи',
    link: 'hand/Uraken-mawashi-uchi.jpg',
    description: 'Круговой удар уракэн в голову.',
  },
  {
    name: 'Шуто Гаммэн Учи',
    link: 'hand/Shuto-yoko-ganmen-uchi.jpg',
    description: 'Удар шуто сбоку по голове или по шее.',
  },
  {
    name: 'Шуто Сакоцу Учи',
    link: 'hand/Shuto-sakotsu-uchi.jpg',
    description: 'Удар шуто сверху по ключице.',
  },
  {
    name: 'Шуто Сакоцу Учикоми',
    link: 'hand/Shuto-sakotsu-uchikomi.jpg',
    description: 'Удар шуто прямой в ключицу.',
  },
  {
    name: 'Шуто Хизо Учи',
    link: 'hand/Shuto-hizo-uchi.jpg',
    description: 'Удар шуто сбоку по рёбрам.',
  },
  {
    name: 'Шуто Учи Учи',
    link: 'hand/Shuto-jodan-uchi-uchi.jpg',
    description: 'Удар шуто наотмаш по голове или шее.',
  },
  {
    name: 'Маэ Хиджи Атэ',
    link: 'hand/Mae-Hiji-Ate.jpg',
    description: 'Удар хиджи вперед.',
  },
  {
    name: 'Агэ Хиджи Атэ',
    link: 'hand/Age-Hiji-Ate.jpg',
    description: 'Удар хиджи вверх.',
  },
  {
    name: 'Уширо Хиджи Атэ',
    link: 'hand/Ushiro-Hiji-Ate.jpg',
    description: 'Удар хиджи назад.',
  },
  {
    name: 'Ороши Хиджи Атэ',
    link: 'hand/Oroshi-Hiji-Ate.jpg',
    description: 'Удар хиджи вниз.',
  },
]

export const udachsti = [
  {
    name: 'Сэйкен',
    link: 'udachasti/Seiken.png',
    description: 'Кулак, передняя часть.',
  },
  {
    name: 'Уракен',
    link: 'udachasti/Uraken.png',
    description: 'Перевёрнутый кулак, тыльная часть.',
  },
  {
    name: 'Шуто',
    link: 'udachasti/Shuto.png',
    description: 'Рука-нож. Ребро ладони.',
  },
  {
    name: 'Хайто',
    link: 'udachasti/Haito.png',
    description:
      'Место между основаниями большого и указательного пальца, большой палец прижат.',
  },
  {
    name: 'Тэццуй',
    link: 'udachasti/Tetsui.png',
    description: 'Кулак-молот. Основание сжатого кулака со стороны мизинца.',
  },
  {
    name: 'Нукитэ',
    link: 'udachasti/Nukite.png',
    description:
      'Рука-копьё. Пальцы сложены вместе и напряжены, средний палец немного согнут, чтобы торцы трёх пальцев были на одной линии.',
  },
  { name: 'Кокэн', link: 'udachasti/Koken.png', description: 'Запястье.' },
  { name: 'Хиджи', link: 'udachasti/Hiji.png', description: 'Локоть.' },
  {
    name: 'Тайсоку',
    link: 'udachasti/Teisoku.png',
    description: 'Внутренняя сторона стопы.',
  },
  { name: 'Аши', link: 'udachasti/Ashi.png', description: 'Стопа, нога.' },
  {
    name: 'Сокуто',
    link: 'udachasti/sokuto.png',
    description: 'Нога-нож. Внешнее ребро стопы.',
  },
  {
    name: 'Хайсоку',
    link: 'udachasti/Heisoku.png',
    description: 'Подъём стопы, носко тянем вперёд, пальцы подгибаем вниз.',
  },
  {
    name: 'Какато',
    link: 'udachasti/Kakato.png',
    description: 'Пятка, носок тянем на себя.',
  },
  { name: 'Сунэ', link: 'udachasti/Sune.png', description: 'Голень.' },
  { name: 'Хидза', link: 'udachasti/Hiza.png', description: 'Колено.' },
]

export const stoiki = [
  {
    name: 'Фудо дачи',
    link: 'stoiki/Fudo-dachi.png',
    description: 'Пятки на ширине плеч, носки развёрнуты',
  },
  {
    name: 'Хейко дачи',
    link: 'stoiki/Heiko-dachi.png',
    description: 'Ступни паралельно на ширине ступни',
  },
  {
    name: 'Хейсоку дачи',
    link: 'stoiki/Heisoku-dachi.png',
    description: 'Ступни вместе',
  },
  {
    name: 'КакэАши дачи',
    link: 'stoiki/Kakcashi-dachi.png',
    description: 'Скрученная стойка с заступом ноги сзади',
  },
  {
    name: 'Киба дачи',
    link: 'stoiki/Kiba-dachi.png',
    description:
      'Стойка всадника. Растояние между ступнями в 2 раза больше ширины плч ',
  },
  {
    name: 'Кокоцу дачи',
    link: 'stoiki/Kokutsu-dachi.png',
    description:
      'Стойка с задней прикланённой ногой. Между передней стопой и задней растояние две с половиной стопы',
  },
  {
    name: 'Мораши дачи',
    link: 'stoiki/Moroashi-dachi.png',
    description: 'Стойка с выдвинутой стопой вперёд',
  },
  {
    name: 'Мусуби дачи',
    link: 'stoiki/Musubi-dachi.png',
    description: 'Пятки вместе носки врозь',
  },
  {
    name: 'НэкоАши дачи',
    link: 'stoiki/Neko-ashi-dachi.png',
    description: 'Стойка кота. Впереди стоящая стопа стоит на кончиках пальцев',
  },
  {
    name: 'Санчин дачи',
    link: 'stoiki/Sanchin-dachi-1.png',
    description:
      'Стойка песочных часов. Растояние между ногами - длина стопы, носки развёрнуты во внутрь, пальцы задней ноги на одной линии с пяткой передней ноги.',
  },
  {
    name: 'Шико дачи',
    link: 'stoiki/Shiko-dachi.png',
    description: 'Стойка борца сумо',
  },
  {
    name: 'СотоХачиджи дачи',
    link: 'stoiki/Soto-hachiji-dachi.png',
    description: 'Пятки на ширине плеч, носки развёрнуты',
  },
  {
    name: 'Тсурашин дачи',
    link: 'stoiki/Tsuru-ashi-dachi.png',
    description: 'Стойка цапли',
  },
  {
    name: 'УчиХачиджи дачи',
    link: 'stoiki/Uchi-hachiji-dachi.png',
    description: 'Пятки на ширине плеч, носки внутрь',
  },
  {
    name: 'Зенкуцу дачи',
    link: 'stoiki/Zenkutsu-dachi.png',
    description:
      'Стойка лучника. Растояние между ногами в раза больше ширины плеч',
  },
]
