import React, { useCallback, useContext, useEffect, useState } from "react"
import { PaymentContext } from "../context/PaymentContext"

const Payments = () => {
  const {
    getIventMember,
    allMomentPay,
    allDebitors,
    allTarif,
    allIvent,
    getTarifCost,
    getIventCost,
    getIventName,
    ykassaPay,
  } = useContext(PaymentContext)

  const [isLoading, setIsLoading] = useState(true)

  const payHandler = async (debid) => {
    await ykassaPay(debid)
  }

  useEffect(
    useCallback(() => {
      setIsLoading(false)
      return () => setIsLoading(true)
    })
  )

  if (!isLoading) {
    return (
      <div className="pb-28 px-5 mt-10">
        <div className="px-10 pt-5">
          <span className=" text-gray-800 font-semibold text-justify">
            Тут будут появляться сообщения об оплате взносов и мероприятий
          </span>
        </div>
        {allTarif.filter((t) => !t.isPayd).length > 0 && (
          <div className="flex flex-col justify-center items-center mt-5">
            <span className="text-lg font-semibold mb-2">Взносы</span>
            {allTarif.map((t) => {
              return (
                <div
                  key={t._id}
                  className=" rounded-lg flex flex-col justify-around items-center w-full mt-3 border-2 border-gray-300 p-5 bg-gray-200">
                  <span className="mb-5 font-bold">{t.month}</span>
                  {t.isPayd ? (
                    <span>Оплачено</span>
                  ) : (
                    <div
                      onClick={() => {
                        payHandler(t._id)
                      }}
                      className=" border-2 h-8 px-4 items-center justify-center rounded-md border-red-700">
                      Оплатить {getTarifCost(t.paysID)} р.
                    </div>
                  )}
                </div>
              )
            })}
          </div>
        )}
        {allIvent.filter((t) => !t.isPayd).length > 0 && (
          <div className="flex flex-col justify-center items-center mt-5">
            <span className="text-lg font-semibold mb-2">Мероприятия</span>
            {allIvent.map((t) => {
              return (
                <div
                  key={t._id}
                  className=" rounded-lg flex flex-col justify-around items-center w-full mt-3 border-2 border-gray-300 p-5 bg-gray-200">
                  <span className="mb-3 font-bold">{getIventName(t.iventID)}</span>
                  <span className="mb-5">{getIventMember(t.memberID).lname}</span>
                  {t.isPayd ? (
                    <span>Оплачено</span>
                  ) : (
                    <div
                      onClick={() => {
                        payHandler(t._id)
                      }}
                      className=" border-2 h-8 px-4 items-center justify-center rounded-md border-red-700">
                      Оплатить {getIventCost(t.iventID)} р.
                    </div>
                  )}
                </div>
              )
            })}
          </div>
        )}
      </div>
    )
  }
}

export default Payments
