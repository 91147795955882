import React from "react"
import { AuthProvider } from "./AuthContext"
import { MemberProvider } from "./MemberContext"
import { PaymentProvider } from "./PaymentContext"
import { EventProvider } from "./EventContext"

const MainProvider = ({ children }) => {
  return (
    <AuthProvider>
      <MemberProvider>
        <PaymentProvider>
          <EventProvider>{children}</EventProvider>
        </PaymentProvider>
      </MemberProvider>
    </AuthProvider>
  )
}

export default MainProvider
