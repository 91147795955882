import React, { useContext } from "react"
import { EventContext } from "../context/EventContext"

const Events = () => {
  const { allEventList, allCalendar } = useContext(EventContext)

  return (
    <div className="pb-28 mt-10">
      {allEventList.length > 0 && (
        <div className="flex flex-col items-center px-3">
          <span className=" font-semibold text-rose-400 text-lg">Ваши события</span>
          {allEventList.map((i) => {
            return (
              <div
                key={i._id}
                className=" rounded-lg flex flex-col justify-around items-center w-full mt-3 border-2 border-gray-300 p-5 bg-gray-200">
                <span className="text-base font-semibold">{i.name}</span>
                <div className="flex w-full justify-between mt-5">
                  <span>{i.date}</span>
                  <span>{i.place}</span>
                </div>
              </div>
            )
          })}
        </div>
      )}
      <div className="flex flex-col items-center px-3">
        <span className=" font-semibold text-rose-400 text-lg">Календарный план</span>
        {allCalendar.map((i) => {
          return (
            <div
              key={i._id}
              className=" rounded-lg flex flex-col justify-around items-center w-full mt-3 border-2 border-gray-300 p-5 bg-gray-200">
              <span className="text-base font-semibold">{i.name}</span>
              <div className="flex w-full justify-between mt-5">
                <span>{i.date}</span>
                <span>{i.place}</span>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Events
