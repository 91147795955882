import Profile from "../screen/Profile"
import Events from "../screen/Events"
import Payments from "../screen/Payments"
import Info from "../screen/Info"
import Login from "../screen/Login"
import Register from "../screen/Register"
import Arhiv from "../screen/Arhiv"
import {
  ARHIV_ROUTE,
  EVENTS_ROUTE,
  INFO_ROUTE,
  LOGIN_ROUTE,
  PAYMENTS_ROUTE,
  PROFILE_ROUTE,
  REGISTER_ROUTE,
} from "../utils/const"

export const publicRouter = [
  {
    path: LOGIN_ROUTE,
    Component: Login,
  },
  {
    path: REGISTER_ROUTE,
    Component: Register,
  },
]

export const authRouter = [
  {
    path: PROFILE_ROUTE,
    Component: Profile,
  },
  {
    path: PAYMENTS_ROUTE,
    Component: Payments,
  },
  { path: EVENTS_ROUTE, Component: Events },
  { path: INFO_ROUTE, Component: Info },
  { path: ARHIV_ROUTE, Component: Arhiv },
]
